<template>
    <div class="setpassword content_getresponse">
        <router-link :to="{name : 'integrations'}" class="close_icon">
            <i class="fal fa-times"></i>
        </router-link>

        <div class="setpassowrd_inner container">

            <div class="top_content_getresponse">
                <img class="get_response_img" src="/img/integrations/benchmark.jpeg" alt="">
                <h2>Connect Benchmark to Your {{ getSiteDetails.agency_name }} Account</h2>
                <p>Enter Your API key to connect.</p>
            </div>

            <transition name="slide">
                <form v-if="!show_lists" class="basic_form clear" action="javascript:;">
                    <div class="input_field col-md-12">
                        <label >Benchmark API Key</label>
                        <input v-model="key" type="text" placeholder="API Key">
                    </div>
                    <div class="col-md-12 text-center btn_sec">
                        <button :disabled="disable_connect"  class="btn---cta btn-blue btn-round" @click.prevent="setBenchMark()">
                            <span>Connect</span>
                        </button>
                    </div>
                </form>
            </transition>

            <transition name="slide">
                <form v-if="show_lists" class="basic_form clear" action="javascript:;">

                    <ul v-if="lists" class="check_list">
                        <li v-for="list in lists" class="checkbox">
                            <input v-model="selected_lists" type="checkbox" :value="list" :id="list.id">
                            <label :for="list.id">{{list.name}}</label>
                        </li>

                    </ul>

                    <div class="col-md-12 text-center btn_sec">
                        <button class="btn---cta btn-blue btn-round" @click.prevent="saveBenchMarkAccount()">
                            <span>Save</span>
                        </button>
                    </div>
                </form>

            </transition>

        </div>
    </div>
</template>

<script>

import { benchMarkConnectURL, benchMarkSaveAccountURL } from '@/config/config.js'
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'
import vueCookie from 'vue-cookie'
import {INTEGRATION_COOKIE} from "../../../constants";

export default (
  {
    components: {
    },
    data () {
      return {
        key: '',
        secret: '',
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,

        reconnect: false,
        account_id: '',
        name: '',
        expire: null,
        integration_id: null
      }
    },
    async created () {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile'])
    },
    methods: {

      setBenchMark () {
        if ((this.key).trim() == '') {
          this.alertMessage('Please enter your Benchmark API key.', 'error')
          return
        }
        this.disable_connect = true
        const integration = this.getIntegrationPayload()
        http.post(benchMarkConnectURL, {
          ...integration,
          key: this.key
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.lists = response.data.lists
                this.show_lists = true
                this.reconnect = response.data.reconnect
                this.account_id = response.data.account_id
                this.name = response.data.name
                this.expire = response.data.expire
                this.integration_id = response.data.integration_id
              } else {
                vueCookie.set(INTEGRATION_COOKIE, JSON.stringify(integration));
                this.disable_connect = false
                this.alertMessage(response.data.message, 'error')
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      },

      saveBenchMarkAccount () {
        if (this.selected_lists.length == 0) {
          this.alertMessage('Please select atleast one list.', 'error')
          return
        }
        http.post(benchMarkSaveAccountURL, {
          key: this.key,
          selected_lists: this.selected_lists,
          reconnect: this.reconnect,
          account_id: this.account_id,
          name: this.name,
          integration_id: this.integration_id,
          expire: this.expire
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
              }
            }
          },
          response => {
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      }
    }
  }
)
</script>
